const configContext = React.createContext(null)

export function useConfig() {
  const context = React.useContext(configContext)
  if (context === null) throw new Error('Please provide a config context')
  return context
}

export function ConfigProvider({ value, children }) {
  return (
    <configContext.Provider {...{ value }}>
      { children }
    </configContext.Provider>
  )
}
