import WebMercatorViewport from 'viewport-mercator-project'
import { FlyToInterpolator } from 'react-map-gl'
import { Map } from '/sub/map/Map'
import { StoryTeaserAnimated } from '/sub/story/StoryTeaser'
import styles from './Overview.css'
import { usePageRouteData } from '/machinery/PageRouteData'
import { Marker } from '/sub/map/Marker'
import groq from 'groq'
import { useNavigate, useRoutes } from '@kaliber/routing'


const ZOOMED_IN_ZOOM = 19
const StoryMarkersMemo = React.memo(StoryMarkers)

Overview.fetchGeoStories = async function fetchGeoStories(client, storyline) {
  return client.fetch(
    groq`
      *[_type == 'geoStory' && storyline._ref == $id]
      {
        ...,
        'id': _id,
        'slug': slug.current,
        'geoLocation': {
          'latitude': geoLocation.lat,
          'longitude': geoLocation.lng
        },
        chapters[] {
          ...,
          'id': _key,
          image { ..., asset-> },
          image360 {
            ...,
            poster { ..., asset-> },
            image { ..., asset-> }
          },
          video {
            ...,
            'src': src.asset->url,
            poster { ..., asset-> }
          },
        }
      } |
      order(documentOrder asc)
    `,
    { id: storyline.id })
}

Overview.fetchStoryData = function fetchData({ params: { storySlug }, parentData: { stories } }) {
  const story = stories.find(x => x.slug === storySlug)
  if (!story) return null

  return { story }
}

export function Overview({ width, height, stories, inert }) {
  const { data } = usePageRouteData()
  const { story: selectedStory = null } = data || {}
  const routes = useRoutes()

  const pitch = selectedStory ? 35 : 0
  const [viewport, setViewport] = React.useState(
    () => ({
      pitch,
      zoom: selectedStory ? ZOOMED_IN_ZOOM : 10,
      ...(selectedStory ? selectedStory.geoLocation : getCenter(stories.map(x => x.geoLocation)))
    })
  )

  // Whenever the latLng changes, update the map accordingly
  React.useEffect(
    () => {
      if (inert || !width || !height) return
      if (selectedStory) {
        setViewport(viewport => ({
          ...viewport,
          ...selectedStory.geoLocation,
          zoom: ZOOMED_IN_ZOOM,
          pitch: 35,
          transitionInterpolator: new FlyToInterpolator()
        }))
      } else {
        setViewport(viewport => {
          const boundingBox = calculateBoundingBox(stories.map(x => x.geoLocation))
          const mercatorViewport = new WebMercatorViewport({ ...viewport, width, height })
          const bounds = mercatorViewport.fitBounds(boundingBox, {
            padding: Math.min(window.innerWidth * 0.2, window.innerHeight * 0.2)
          })

          return {
            ...viewport,
            ...bounds,
            transitionInterpolator: new FlyToInterpolator()
          }
        })
      }
    },
    [inert, width, height, stories, selectedStory]
  )

  return (
    <Map onViewportChange={handleViewportChange} {...{ viewport }}>
      <div className={styles.component}>
        <StoryMarkersMemo
          {...{ stories, selectedStory }}
        />
        <div className={styles.storyTeaser}>
          <StoryTeaserAnimated story={selectedStory} storyContainerRoute={routes.geo} />
        </div>
      </div>
    </Map>
  )

  function handleViewportChange(viewport, interactionState) {
    if (!interactionState.isTransition) {
      viewport.transitionInterpolator = undefined
    }

    setViewport(viewport)
  }
}


function StoryMarkers({ selectedStory, stories }) {
  const navigate = useNavigate()
  /** @type {import('/routeMap').routeMap['storyline']} */
  const routes = useRoutes()

  return (
    stories.map(story => {
      const { latitude, longitude } = story.geoLocation
      return (
        <Marker
          key={story.id}
          active={story.id === selectedStory?.id}
          title={story.title}
          onClick={() => navigate(routes.geo.story({ storySlug: story.slug }))}
          {...{ latitude, longitude }}
        />
      )
    })
  )
}


function calculateBoundingBox(latLngs) {
  const lats = latLngs.map(x => x.latitude)
  const lngs = latLngs.map(x => x.longitude)
  const minLat = Math.min(...lats)
  const maxLat = Math.max(...lats)
  const minLng = Math.min(...lngs)
  const maxLng = Math.max(...lngs)
  return [[minLng, minLat], [maxLng, maxLat]]
}

function getCenter(latLngs) {
  const [[minLng, minLat], [maxLng, maxLat]] = calculateBoundingBox(latLngs)
  return { latitude: (minLat + maxLat) / 2, longitude: (minLng + maxLng) / 2 }
}
