import { Icon } from '/sub/Icon'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'
import styles from './Button.css'
import { Link } from '@kaliber/routing'


export function ButtonCallToActionWhite({ children, to }) {
  return (
    <ButtonBase {...{ children, to }} layoutClassName={styles.componentCallToActionWhite} />
  )
}

export function ButtonCallToActionTransparent({ children, to }) {
  return (
    <ButtonBase {...{ children, to }} layoutClassName={styles.componentCallToActionTransparent} />
  )
}

function ButtonBase({ children, to, layoutClassName }) {
  return (
    <Link
      anchorProps={{ className: cx(styles.componentCallToActionBase, layoutClassName) }}
      {...{ to }}
    >
      <span className={styles.text}>{ children }</span>
      <span className={styles.iconRight}>
        <Icon icon={iconArrowRight} />
      </span>
    </Link>
  )
}
