import { Image } from '/sub/Image'
import styles from './ChapterImage.css'

import { HeadingLg } from '/sub/typography/Heading'

export function ChapterImage({ title, image, layoutClassName, onReadyChange }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.image}>
        <Image cover key={image.asset._id} onLoad={() => onReadyChange(true)} layoutClassName={styles.imageLayout} aspectRatio={5 / 9} {...{ image }} />
      </div>
      <div className={styles.details}>
        <div className={styles.text}>
          <HeadingLg>{title}</HeadingLg>
        </div>
      </div>
    </div>
  )
}
