import { Container } from '/sub/layout/Container'
import { HeadingSm } from '/sub/typography/Heading'
import { LinkButton } from '/sub/button/LinkButton'
import { Icon } from '/sub/Icon'
import ArrowLeft from '/images/icons/arrow-left.raw.svg'
import styles from './StorylineHeader.css'
import { useLocationMatch, useMatchedRoute, usePick } from '@kaliber/routing'
import { routeMap } from '/routeMap'
import { usePageRouteData } from '/machinery/PageRouteData'

export function StorylineHeader({ title }) {
  const to = useBackLink()

  return (
    <Container>
      <div className={styles.component}>
        <LinkButton {... { to }}>
          <Icon label='icon' icon={ArrowLeft} />
          <span>Terug</span>
        </LinkButton>
        <div className={styles.heading}>
          <HeadingSm h={1}>{title}</HeadingSm>
        </div>
      </div>
    </Container>
  )
}

function useBackLink() {
  const { params } = useLocationMatch()
  const storylineRoute = useMatchedRoute()
  const pick = usePick()
  const { data } = usePageRouteData()

  if (pick(storylineRoute.floorPlan.floor.story) && data.floor?.showTeaser) return storylineRoute.floorPlan.floor(params)
  if (pick(storylineRoute.geo.story)) return storylineRoute()
  if (pick(storylineRoute.floorPlan.floor) && data.floors?.length !== 1) return storylineRoute()

  return routeMap.home()
}
