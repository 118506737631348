import { Floors } from '/sub/storyline/floor/Floors'
import { StoryTeaserAnimated } from '/sub/story/StoryTeaser'
import styles from './Overview.css'
import groq from 'groq'
import { usePageRouteData } from '/machinery/PageRouteData'
import { useRoutes } from '@kaliber/routing'

// TODO: we should actually only fetch the data required for the overview and fetch the complete
// floor once it has been selected
Overview.fetchFloors = async function fetchFloors(client, storyline) {
  return client.fetch(
    groq`
      *[_type == 'floor' && storyline._ref == $id]
      {
        ...,
        'id': _id,
        'slug': slug.current,
        title,
        image { ..., asset-> }
      } |
      order(documentOrder asc)
    `,
    { id: storyline.id }
  )
}

export function Overview({ floors }) {
  const { data } = usePageRouteData()
  const { story: selectedStory = null, floor = null } = data || {}
  const routes = useRoutes()

  return (
    <div className={styles.component}>
      <Floors {...{ floors }} />
      {floor?.showTeaser &&
        <div className={styles.storyTeaser}>
          <StoryTeaserAnimated story={selectedStory} storyContainerRoute={routes.floorPlan.floor} />
        </div>
      }
    </div>
  )
}
