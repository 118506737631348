import { Container } from '/sub/layout/Container'
import { Drawer } from '/sub/drawer/Drawer'
import { StoryDrawerButton } from '/sub/storyline/StoryDrawerButton'
import styles from './StoryDrawer.css'

// TODO: disable and mark active story
export function StoryDrawer({ stories, selectedStory, storyContainerRoute }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  return (
    <Drawer
      visible={Boolean(stories.length)}
      open={drawerOpen}
      onOpen={() => setDrawerOpen(true)}
      onClose={() => setDrawerOpen(false)}
      openY={0}
      closedY={90}
    >
      <Container>
        <div className={styles.component}>
          <ul className={styles.list}>
            {stories.map(({ id, slug, title, description, image, floor }) => {
              const to = storyContainerRoute.story({ storySlug: slug })

              return (
                <li className={styles.listItem} key={id}>
                  <StoryDrawerButton
                    {...{ title, description, image, to }}
                    onClick={() => setDrawerOpen(false)}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </Container>
    </Drawer>
  )
}
