import { animated } from 'react-spring'
import { useAnimatedValue } from '/machinery/useAnimatedValue'
import { useRequestAnimationFrameProgressTracker } from '/machinery/useRequestAnimationFrameProgressTracker'
import { HeadingMd as StoryHeading } from '/sub/typography/Heading'
import { Chapter360 } from '/sub/story/chapters/Chapter360'
import { ChapterQuote } from '/sub/story/chapters/ChapterQuote'
import { ChapterImage } from '/sub/story/chapters/ChapterImage'
import { ChapterImageWithText } from '/sub/story/chapters/ChapterImageWithText'
import { ChapterVideo } from '/sub/story/chapters/ChapterVideo'
import { ChapterLink } from '/sub/story/chapters/ChapterLink'
import iconClose from '/images/icons/close.raw.svg'
import styles from './StorySlide.css'
import { useSettings } from '/machinery/Settings'

export function StorySlide({
  active,
  onNext,
  onClose,
  running,
  onRunningChange,
  chapters,
  chapterIndex,
  title,
  description = undefined,
}) {
  const settings = useSettings()
  const defaultDuration = settings.transitionDelay || 8000

  const chapter = chapters ? chapters[chapterIndex] : null
  const [ready, setReady] = useChapterReady({ chapters, chapterIndex })
  const [duration, setDuration] = React.useState(defaultDuration)
  const [progress, setProgress] = useAnimatedValue('progress', 0)
  React.useMemo(
    () => setDuration(chapter?.transitionDelay ?? defaultDuration),
    [chapter?.transitionDelay, defaultDuration]
  )

  // TODO: Refactor. Example: Veteranen Instituut ArticleCarouselFeatured
  const { start, pause, reset } = useRequestAnimationFrameProgressTracker({
    duration,
    onTick: p => setProgress(p + chapterIndex),
    onComplete: onNext,
  })

  React.useEffect(
    () => {
      if (!active) return
      reset()
      start()
    },
    [active, chapterIndex, reset, start]
  )

  React.useEffect(
    () => { (active && ready && running) ? start() : pause() },
    [active, ready, running, start, pause]
  )

  return (
    <div
      className={styles.component}
      onContextMenu={e => /* TODO: this is not handy during development */ e.preventDefault()}
    >
      <div className={styles.ui}>
        <Header
          pagination={
            <Pagination count={chapters.length} {...{ progress }} />
          }
          {...{ title, description, onClose }}
        />
      </div>

      {
      /* There are 3 states that affect wether a chapter is running:
       * - active: wether the story is in focus
       * - ready: wether the media for this chapter is ready
       * - running: wether this chapter should be running, based on interaction
       *
       * A chapter can call...
       * - ...onReadyChange(false) when it needs to load more data
       * - ...onRunningChange(false) when it needs to pause autoplay, regardless of data
       */
      }

      {chapter && (
        <>
          {chapter.chapterType === '360' && <Chapter360
            layoutClassName={styles.chapter}
            title={chapter.title}
            image360={chapter.image360}
            onReadyChange={setReady}
            {...{ onRunningChange }}
          />}
          {chapter.chapterType === 'quote' && <ChapterQuote
            layoutClassName={styles.chapter}
            text={chapter.text}
            color={chapter.color}
            onReadyChange={setReady}
          />}
          {chapter.chapterType === 'image' && <ChapterImage
            layoutClassName={styles.chapter}
            title={chapter.title}
            image={chapter.image}
            onReadyChange={setReady}
          />}
          {chapter.chapterType === 'imageWithText' && <ChapterImageWithText
            layoutClassName={styles.chapter}
            title={chapter.title}
            text={chapter.text}
            color={chapter.color}
            image={chapter.image}
            onReadyChange={setReady}
          />}
          {chapter.chapterType === 'video' && <ChapterVideo
            layoutClassName={styles.chapter}
            title={chapter.title}
            video={chapter.video}
            onReadyChange={setReady}
            onDurationChange={setDuration}
            running={active && ready && running}
          />}
          {chapter.chapterType === 'documentLink' && <ChapterLink
            layoutClassName={styles.chapter}
            title={chapter.title}
            link={chapter.link}
            color={chapter.color}
            onReadyChange={setReady}
          />}
        </>
      )}
    </div>
  )
}

function Header({ pagination, onClose, title, description }) {
  return (
    <div className={styles.componentHeader}>
      <div className={styles.descriptionAndClose}>
        <div className={styles.description}>
          <StoryHeading h={2} layoutClassName={styles.heading}>{title}</StoryHeading>
          <p className={styles.descriptionText}>{description}</p>
        </div>
        <button className={styles.close} onClick={onClose}>
          <span dangerouslySetInnerHTML={{ __html: iconClose }} />
        </button>
      </div>
      <div className={styles.pagination}>
        {pagination}
      </div>
    </div>
  )
}

function Pagination({ count, progress }) {
  const range = Array.from(Array(count).keys())

  return (
    <div className={styles.componentPagination}>
      {range.map(i => (
        <div key={i} className={styles.item}>
          <animated.div
            className={styles.fill}
            style={{ transform: progress.interpolate(p =>
              i > p ? `scaleX(0)` :
              p - i < 1 ? `scaleX(${ p - i })` :
              `scaleX(1)`)
            }}
          />
        </div>
      ))}
    </div>
  )
}

function useChapterReady({ chapters, chapterIndex }) {
  const [ready, setReady] = React.useState(() => chapters.map(() => false))

  const chapterReady = ready[chapterIndex]
  const setChapterReady =  React.useCallback(
    chapterReady => {
      setReady(ready => [
        ...ready.slice(0, chapterIndex),
        chapterReady,
        ...ready.slice(chapterIndex + 1)
      ])
    },
    [chapterIndex]
  )

  return [chapterReady, setChapterReady]
}
