import { Image } from '/sub/Image'
import { HeadingMd } from '/sub/typography/Heading'
import { TextSmall } from '/sub/typography/Text'
import svgCurl from '/images/content-box-curl.raw.svg'
import styles from './ChapterImageWithText.css'

export function ChapterImageWithText({ title, text, image, layoutClassName, onReadyChange, color }) {
  const stylesColor = (
    color === 'white' ? styles.white :
    color === 'green' ? styles.green :
    color === 'blue' ? styles.blue :
    color === 'turquoise' ? styles.turquoise :
    styles.turquoise
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.image}>
        <Image cover onLoad={() => onReadyChange(true)} layoutClassName={styles.imageLayout} aspectRatio={1} {...{ image }} />
      </div>
      <div className={styles.details}>
        <div className={cx(styles.text, stylesColor)}>
          <div
            className={cx(styles.curl, stylesColor)}
            dangerouslySetInnerHTML={{ __html: svgCurl }}
          />
          <HeadingMd h={2}>{title}</HeadingMd>
          <TextSmall>{text}</TextSmall>
        </div>
      </div>
    </div>
  )
}
