export function usePortal(className) {
  const [portalNode, setPortalNode] = React.useState(null)

  React.useEffect(
    () => {
      const div = document.createElement('div')
      div.className = className
      setPortalNode(div)
      document.body.appendChild(div)

      return () => document.body.removeChild(div)
    },
    [className]
  )

  return portalNode
}
