const settingsContext = React.createContext(null)

export function useSettings() {
  const context = React.useContext(settingsContext)
  if (context === null) throw new Error('Please provide a config context')
  return context
}

export function SettingsProvider({ value, children }) {
  return (
    <settingsContext.Provider {...{ value }}>
      { children }
    </settingsContext.Provider>
  )
}
