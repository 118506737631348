import styles from './Link.css'
import { Link } from '@kaliber/routing'

export { LinkBase as Link }

export function LinkText({ to, children, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return <LinkBase className={styles.componentText} {...{ to, children, target, rel, onClick, layoutClassName }} />
}

export function LinkBlock({ to, children, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return <LinkBase className={styles.componentBlock} {...{ to, children, target, rel, onClick, layoutClassName }} />
}

function LinkBase({ to, children, className, target, rel, onClick, layoutClassName }) {
  const Base = (isRelative(to) && !target) ? LinkBaseRelative : LinkBaseAbsolute
  const safeRel = target === '_blank' ? `${rel || ''} noopener noreferrer` : rel
  return (
    <Base
      rel={safeRel}
      className={cx(styles.componentBase, className, layoutClassName)}
      {...{ to, target, onClick, children }} />
  )
}

function LinkBaseAbsolute({ to, children, className, target, rel, onClick }) {
  return (
    <a href={to} {...{ className, target, rel, onClick }}>{children}</a>
  )
}

function LinkBaseRelative({ to, children, className, target, rel, onClick }) {
  const anchorProps = { className, rel, target, onClick }
  return (
    <Link {...{ to, anchorProps }}>{children}</Link>
  )
}

const absoluteLinkPattern = /^https?:\/\/|^\/\//i

function isRelative(href) {
  return !absoluteLinkPattern.test(href)
}

