import { useTransition, animated } from 'react-spring'
import { Container } from '/sub/layout/Container'
import { HeadingMd } from '/sub/typography/Heading'
import { ButtonCircleLink } from '/sub/button/ButtonCircle'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'
import styles from './StoryTeaser.css'
import { useMatchedRoute, usePick } from '@kaliber/routing'

export function StoryTeaserAnimated({ story, storyContainerRoute }) {
  const transitions = useTransition(story, x => x ? x.id : null, {
    from: { opacity: 1, transform: 'translate3d(0, 15vh, 1px)', width: '100%' },
    enter: { opacity: 1, transform: 'translate3d(0, 0vh, 1px)', width: '100%' },
    leave: { opacity: 0, transform: 'translate3d(0, 7.5vh, 0px)', position: 'absolute', bottom: 0, width: '100%' }
  })
  return (
    <div>
      {transitions.map(
        ({ item: story, key, props }) =>
          story && (
            <animated.div style={props} {...{ key }}>
              <StoryTeaser {... { story, storyContainerRoute }} />
            </animated.div>
          )
      )}
    </div>
  )
}

function StoryTeaser({ story: { title, description }, storyContainerRoute }) {
  const pick = usePick()
  const { route: currentStoryRoute } = pick(storyContainerRoute.story) || {}
  const detailUrl = currentStoryRoute?.detail() || '' // we might be animating out

  return (
    <Container>
      <div className={styles.component}>
        <div className={styles.header}>
          <HeadingMd>{title}</HeadingMd>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.button}>
          <ButtonCircleLink to={detailUrl} icon={iconArrowRight}>Open verhaal: {title}</ButtonCircleLink>
        </div>
      </div>
    </Container>
  )
}
