import { useImageBuilder } from '/machinery/Sanity'
import { reportError } from '/machinery/reportError'
import styles from './ThreeSixtyImage.css'

export function ThreeSixtyImage({ image }) {
  const [ready, setReady] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const src = useImage(image)

  React.useEffect(
    () => { import('aframe').then(() => setReady(true)).catch(reportError) },
    []
  )

  return (
    <div
      className={styles.component}
      // Prevent dragging events to bleed through
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
    >
      <div className={styles.aframe} style={{ opacity: (ready && loaded) ? 1 : 0 }}>
        {ready && (
          <a-scene vr-mode-ui="enabled: false" loading-screen="backgroundColor: #1c1c1c">
            <a-assets>
              <img alt='360' id="sky" {...{ src }} onLoad={() => setLoaded(true)} crossOrigin='' />
            </a-assets>
            <a-sky src="#sky" rotation="0 -130 0" />
            <a-camera look-controls="reverseMouseDrag: true;" />
          </a-scene>
        )}
      </div>
    </div>
  )
}

function useImage(image) {
  const builder = useImageBuilder()
  const [src, setSrc] = React.useState(null)

  React.useEffect(
    () => {
      const src = builder.image(image).width(6000).auto('format').url()
      setSrc(src)
    },
    [builder, image]
  )

  return src
}

function stopPropagation(e) {
  e.stopPropagation()
}
