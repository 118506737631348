import groq from 'groq'
import { useElementSize } from '@kaliber/use-element-size'
import { usePageRouteData, usePageRoutePrefetch } from '/machinery/PageRouteData'
import { StorylineHeader } from '/sub/storyline/StorylineHeader'
import { Overview as OverviewGeo } from '/sub/storyline/geo/Overview'
import { Overview as OverviewFloor } from '/sub/storyline/floor/Overview'
import { StoryCarousel } from '/sub/story/StoryCarousel'
import { ModalPortrait } from '/sub/modal/Modal'
import styles from './Storyline.css'
import { useMatchedRoute, useNavigate, usePick, useRoutes } from '@kaliber/routing'
import { StoryDrawer } from '/sub/storyline/StoryDrawer'
import { routeMap } from '/routeMap'

const { fetchFloors } = OverviewFloor
const { fetchGeoStories } = OverviewGeo

Storyline.fetchData = async ({ client, params: { storylineSlug } }) => {
  if (!storylineSlug) return null

  const storyline = await client.fetch(
    groq`*[_type == 'storyline' && slug.current == $storylineSlug] [0] {
      ...,
      'id': _id,
      'slug': slug.current
    }`,
    { storylineSlug }
  )

  if (!storyline) return null

  const { overviewType } = storyline
  return (
    overviewType === 'floor' ? { storyline, floors: await fetchFloors(client, storyline) } :
    overviewType === 'geo' ? { storyline, stories: await fetchGeoStories(client, storyline) } :
    null
  )
}

export function Storyline() {
  const navigate = useNavigate()
  /** @type {import('/routeMap').routeMap['storyline']} */
  const routes = useRoutes()
  const storylineRoute = useMatchedRoute()

  const { data } = usePageRouteData()
  const {
    storyline = null,
    stories = [],
    floors = [],
    floor = null,
    story: selectedStory = null
  } = data || {}

  const pick = usePick()

  const { size: { width, height }, ref: elementRef } = useElementSize()

  const storyRouteInfo = pick(routes.geo.story, routes.floorPlan.floor.story)
  const storyDetailRouteInfo = pick(routes.geo.story.detail, routes.floorPlan.floor.story.detail)
  const viewStoryDetail = Boolean(storyDetailRouteInfo)

  const title = floor?.title || storyline?.title || ''

  const { overviewType } = storyline || {}

  const storyContainerRoute =
    overviewType === 'floor' ? routes.floorPlan.floor :
    overviewType === 'geo' ? routes.geo :
    null

  const pageRouteDataPrefetch = usePageRoutePrefetch()
  React.useMemo(
    () => {
      stories.forEach(x => {
        const route = ( // TODO: once `routes` are stable we could use `storyContainerRoute`
          storyline.overviewType === 'floor' ? routeMap.storyline.floorPlan.floor.story :
          storyline.overviewType === 'geo' ? routeMap.storyline.geo.story :
          null
        )
        const params = { storylineSlug: storyline.slug, storySlug: x.slug, floorSlug: floor?.slug }

        pageRouteDataPrefetch({ route, params, query: {} })
      })
    },
    [stories, pageRouteDataPrefetch, storyline, floor]
  )

  const storiesForCarousel = (
    ['close', 'stop'].includes(storyline?.endOfStoryBehavior) ? [selectedStory].filter(Boolean) :
    storyline?.endOfStoryBehavior === 'nextStory' ? stories :
    stories
  )

  return (
    <>
      <div className={styles.page}>
        <header className={styles.header}>
          <StorylineHeader {...{ title }} />
        </header>

        <div ref={elementRef} className={styles.map}>
          {overviewType === 'floor' && <OverviewFloor
            {...{ floors, backToStoryline }}
          />}
          {overviewType === 'geo' && <OverviewGeo
            inert={viewStoryDetail}
            {...{ width, height, stories, backToStoryline }}
          />}
        </div>

        <ModalPortrait
          visible={viewStoryDetail}
          onClose={closeStoryDetail}
        >
          {selectedStory && <StoryCarousel
            {...{ selectedStory }}
            stories={storiesForCarousel}
            onSelectedStoryChange={handleSelectedStoryChange}
            onClose={closeStoryDetail}
            stopOnEndOfStory={storyline?.endOfStoryBehavior === 'stop'}
          />}
        </ModalPortrait>
      </div>
      <StoryDrawer {...{ stories, selectedStory, storyContainerRoute }} />
    </>
  )

  function handleSelectedStoryChange(newStory) {
    if (!newStory) return backToStoryline()
    navigate(storyRouteInfo.route.detail({ storySlug: newStory.slug }), { replace: true })
  }

  function backToStoryline() {
    navigate(storylineRoute())
  }

  function closeStoryDetail() {
    navigate(storyRouteInfo.route())
  }
}
