import { Icon } from '/sub/Icon'
import styles from './ButtonCircle.css'
import { Link } from '@kaliber/routing'

export function ButtonCircle({ children, onClick, icon, layoutClassName = undefined }) {
  return <CircleBase element='button' type='button' {...{ icon, children, onClick, layoutClassName }} />
}

export function ButtonCircleLink({ children, to, icon, layoutClassName = undefined }) {
  return <CircleBase element={LinkWrapper} {...{ icon, children, to, layoutClassName }} />
}

export function ButtonCircleLinkLarge({ children, to, icon, layoutClassName = undefined }) {
  return <CircleBase element={LinkWrapper} {...{ icon, children, to, layoutClassName }} />
}

function LinkWrapper({ to, children, ...props }) {
  return <Link {...{ to, children }} anchorProps={props} />
}

function CircleBase({ element: Base, children, icon, layoutClassName, ...props }) {
  return (
    <Base
      className={cx(styles.componentCircleBase, icon && styles.hasIcon, layoutClassName)}
      {...props}
    >
      <span className={styles.body}>{children}</span>

      {icon && (
        <span className={styles.icon}>
          <Icon {...{ icon }} />
        </span>
      )}
    </Base>
  )
}
