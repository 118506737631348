import styles from './Section.css'

export function Section({ children }) {
  return (
    <div className={styles.component}>
      {children}
    </div>
  )
}

