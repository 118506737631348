import groq from 'groq'
import { usePageRouteData } from '/machinery/PageRouteData'
import waterLineDesktop from '/images/icons/water-line-desktop.raw.svg'
import waterLineMobile from '/images/icons/water-line-mobile.raw.svg'
import { useMediaQuery } from '/machinery/useMediaQuery'
import { breakpointMd } from '/cssGlobal/media.css'
import { Section } from '/sub/layout/Section'
import { Container } from '/sub/layout/Container'
import { HeadingXl } from '/sub/typography/Heading'
import { RichText } from '/sub/RichText'
import { Icon } from '/sub/Icon'
import { LinkButton } from '/sub/button/LinkButton'
import iconArrowLeft from '/images/icons/arrow-left.raw.svg'
import imageLogoColor from '/images/icons/logo-color.png'
import styles from './Page.css'

Page.fetchData = async ({ client, params: { pageSlug } }) => {
  const page = await client.fetch(
    groq`*[slug.current == $pageSlug && _type == 'page'][0]`,
    { pageSlug }
  )
  return page && { page }
}

export function Page() {
  const { data } = usePageRouteData()
  const { title = '', text = [] } = data.page || {}
  const minMdView = useMediaQuery({ query: `(min-width: ${breakpointMd})`, initialValue: false })

  return (
    <div className={styles.page}>
      <header className={styles.hero}>
        <Container>
          <div className={styles.heroLayout}>
            <HeadingXl h={1}>{title}</HeadingXl>
            <LinkButton to='/'>
              <Icon icon={iconArrowLeft} />
              <span>Terug</span>
            </LinkButton>
          </div>
        </Container>
      </header>

      <div className={styles.logo}>
        <img src={imageLogoColor} alt='Logo' />
      </div>

      <Section>
        <Container>
          <RichText blocks={text.blocks} />
        </Container>
      </Section>

      <div className={styles.waterLine}>
        {minMdView
          ? <Icon icon={waterLineDesktop} />
          : <Icon icon={waterLineMobile} />
        }
      </div>
    </div>
  )
}
