import { reportError } from './reportError'
import { INTERNAL_SERVER_ERROR, NOT_FOUND } from './statusCodes'

export async function determineDocumentPath({ routeMap, client, body }) {
  try {
    const document = body
    const found = findInTree(routeMap, document._type)
    if (!found) return {
      status: NOT_FOUND,
      body: { message: `Could not find type '${document._type}' in tree`, document }
    }
    const { route, extractParams } = found

    const params = await extractParams(document, { client })
    let path
    try { path = route(params) } catch (e) { throw new Error(`${e.message}\nparams: ${JSON.stringify(params)}\ndocument: ${JSON.stringify(document)}`) }
    return { body: { path } }
  } catch (e) {
    reportError(e)
    return {
      status: INTERNAL_SERVER_ERROR,
      body: { error: process.env.NODE_ENV === 'production' ? 'Internal server error' : e.stack }
    }
  }
}

function findInTree(o, type) {
  return Object.values(o).reduce(
    (result, x) =>
      result || (typeof x !== 'object' && typeof x !== 'function') || !x ? result :
      x.data?.extractParams?.[type] ? { route: x, extractParams: x.data.extractParams[type] } :
      findInTree(x, type),
    null
  )
}
