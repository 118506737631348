import { usePageRouteData } from '/machinery/PageRouteData'
import { Section } from '/sub/layout/Section'
import { Container } from '/sub/layout/Container'
import { HeadingXl } from '/sub/typography/Heading'
import { PageTransition } from '/sub/motion/PageTransition'

export function ServerError() {
  const { data } = usePageRouteData()
  const { title = '', stack = '' } = data

  return (
    <PageTransition>
      <Section>
        <Container>
          <HeadingXl h={1}>{title}</HeadingXl>
          <pre style={{ whiteSpace: 'pre-wrap' }}><code>{stack}</code></pre>
        </Container>
      </Section>
    </PageTransition>
  )
}
