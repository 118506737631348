import BlockContent from '@sanity/block-content-to-react'
import { LinkText as Link } from '/sub/buildingBlocks/Link'
import { TextLink } from '/sub/typography/TextLink'
import { ListRegular, ListNumbered } from '/sub/List'
import { HeadingSm, HeadingLg } from '/sub/typography/Heading'
import styles from './RichText.css'

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => <Link to={mark.internalLink}>{children}</Link>,
    link: ({ mark, children }) => <TextLink to={mark.href}>{children}</TextLink>,
  },
  types: {
    block: props => {
      switch (props.node.style) {
        case 'h2': return <HeadingLg h={2} layoutClassName={styles.heading}>{props.children}</HeadingLg>
        case 'h3': return <HeadingSm h={3} layoutClassName={styles.heading}>{props.children}</HeadingSm>
        default: return BlockContent.defaultSerializers.types.block(props)
      }
    }
  },
  container: x => x.children,
  list: ({ type, children }) => {
    switch (type) {
      case 'number':
        return <ListNumbered>{children}</ListNumbered>
      case 'bullet':
        return <ListRegular>{children}</ListRegular>
      default:
        if (process.env.NODE_ENV !== 'production') console.warn(`List type '${type}' missing`)
        return <ListRegular>{children}</ListRegular>
    }
  }
}

export function RichText({ blocks = [] }) {
  return (
    <div className={styles.component}>
      <BlockContent {...{ blocks, serializers }} />
    </div>
  )
}
