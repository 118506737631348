import ReactDOM from 'react-dom'
import { useSpring, animated, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { useElementSize } from '@kaliber/use-element-size'
import { mergeRefs } from '/machinery/mergeRefs'
import { usePortal } from '/machinery/usePortal'
import { usePreventOverscroll } from '/machinery/usePreventOverscroll'
import { deriveDirectionSign } from '/machinery/deriveDirectionSign'
import { Icon } from '/sub/Icon'
import iconPull from '/images/icons/menu.raw.svg'
import styles from './Drawer.css'

const springConfig = { ...config.stiff, tension: 300 }

export { DrawerPortal as Drawer }

function DrawerPortal({ children, visible, open, onOpen, onClose, openY, closedY }) {
  const portalNode = usePortal('_rootDrawer')

  return portalNode
    ? ReactDOM.createPortal(<Drawer {...{ children, visible, open, onOpen, onClose, openY, closedY }} />, portalNode)
    : <Drawer {...{ children, visible, open, onOpen, onClose, openY, closedY }} />
}

function Drawer({ children, visible, open, onOpen, onClose, openY, closedY }) {
  const { size: { height }, ref: sizeRef } = useElementSize()
  const { ref: preventOverscrollRef } = usePreventOverscroll()
  const velocityRef = React.useRef(0)
  const [{ y }, setSpring] = useSpring(() => ({ y: visible ? (open ? openY : closedY) : 100 }))

  const bind = useDrag(({ initial, previous, movement, vxvy, down, tap, event }) => {
    if (down) handleDrag({ y: movement[1] })
    else if (tap) handleTap(event)
    else handleRelease({ offset: previous[1] - initial[1], velocity: vxvy[1] / height * 100 })
  }, {
    axis: 'y',
    rubberband: 0.1,
    captureTaps: true,
    initial: () => [0, y.getValue() / 100 * height],
    bounds: {
      top: openY / 100 * height,
      bottom: closedY / 100 * height,
    },
  })

  React.useEffect(
    () => {
      setSpring({
        y: visible ? (open ? openY : closedY) : 100,
        immediate: false,
        config: { ...springConfig, velocity: velocityRef.current }
      })
    },
    [setSpring, visible, open, openY, closedY]
  )

  return (
    <div ref={mergeRefs(sizeRef, preventOverscrollRef)} className={styles.component_root}>
      <animated.div
        className={styles.panel}
        style={{
          transform: y.interpolate(y => `translate3d(0, ${y}%, 0)`)
        }}
      >
        <button
          className={cx(styles.handle, !open && styles.relativeToParent, open && styles.isOpened)}
          {...bind()}
        >
          <div className={styles.icon}>
            <Icon icon={iconPull} label="Pull" />
          </div>
        </button>
        <div className={styles.content}>{children}</div>
      </animated.div>
    </div>
  )

  function handleDrag({ y }) {
    setSpring({
      y: y / height * 100,
      immediate: true,
      config: { ...springConfig, velocity: 0 }
    })
  }

  function handleRelease({ offset, velocity }) {
    velocityRef.current = velocity

    const sign = deriveDirectionSign({
      velocityThreshold: 0.2,
      offsetThreshold: height * 0.05,
      velocity,
      offset
    })

    setSpring({
      y: open ? openY : closedY,
      immediate: false,
      config: { ...springConfig, velocity }
    })

    if (sign < 0) onOpen()
    else if (sign > 0) onClose()
  }

  function handleTap(e) {
    e.target.blur()
    open ? onClose() : onOpen()
  }
}
