import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

/** @type {React.Context<{ client: ReturnType<typeof sanityClient> }>} */
const sanityContext = React.createContext(null)

export function useSanityContext() {
  const context = React.useContext(sanityContext)
  if (context === null) throw new Error('Please provide a sanity context')
  return context
}

export function useImageBuilder() {
  const { client } = useSanityContext()
  return React.useMemo(() => imageUrlBuilder(client), [client])
}

export function SanityProvider({ config, children }) {
  const value = React.useMemo(
    () => ({ client: sanityClient(config.clients.readOnly) }),
    [config]
  )

  return (
    <sanityContext.Provider {...{ value }}>
      { children }
    </sanityContext.Provider>
  )
}
