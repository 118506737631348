import styles from './Container.css'

export function Container({ children, breakOut = undefined }) {
  return (
    <div className={cx(styles.component, breakOut === 'left' && styles.breakOutLeft, breakOut === 'right' && styles.breakOutRight)}>
      <div className={cx(styles.grid, breakOut === 'left' && styles.breakOutLeft, breakOut === 'right' && styles.breakOutRight)}>
        {children}
      </div>
    </div>
  )
}

