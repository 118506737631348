import { Section } from '/sub/layout/Section'
import { Container } from '/sub/layout/Container'
import { HeadingXl } from '/sub/typography/Heading'
import { TextRegular } from '/sub/typography/Text'
import { PageTransition } from '/sub/motion/PageTransition'

export function NotFound() {
  return (
    <PageTransition>
      <Section>
        <Container>
          <HeadingXl h={1}>Woooops, deze pagina is er niet meer.</HeadingXl>
          <TextRegular>
            Deze pagina konden we helaas niet vinden.
          </TextRegular>
        </Container>
      </Section>
    </PageTransition>
  )
}
