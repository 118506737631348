import { LinkBlock } from '/sub/buildingBlocks/Link'
import { HeadingMd } from '/sub/typography/Heading'
import styles from './StoryDrawerButton.css'
import { ButtonCircleLink } from '/sub/button/ButtonCircle'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'


export function StoryDrawerButton({ title, description, to, onClick }) {
  return (
    <LinkBlock layoutClassName={styles.layout} {...{ to, onClick }}>
      <div className={styles.component}>
        <div>
          <HeadingMd>{title}</HeadingMd>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.button}>
          <ButtonCircleLink {...{ to }} icon={iconArrowRight} children="undefined" />
        </div>
      </div>
    </LinkBlock>
  )
}
