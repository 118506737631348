import { useSpring, useTransition, animated } from 'react-spring'
import { Pin as PinBase } from '/sub/Pin'
import { useImageBuilder } from '/machinery/Sanity'
import iconMarkerActive from '/images/icons/marker-active.raw.svg'
import iconMarkerInactive from '/images/icons/marker-inactive.raw.svg'

import styles from './Floor.css'
import { reportError } from '/machinery/reportError'
import groq from 'groq'
import { usePageRouteData } from '/machinery/PageRouteData'
import { useNavigate, useRoutes } from '@kaliber/routing'

Floor.fetchData = async function fetchData({ client, params: { floorSlug }, parentData: { floors } }) {
  const floor = floors.find(x => x.slug === floorSlug)
  if (!floor) return null

  const stories = await fetchFloorStories(client, floor.id)
  return { floor, stories }
}

async function fetchFloorStories(client, floorId) {
  return client.fetch(
    groq`
      *[_type == 'floorStory' && floorLocation.floor._ref == $floorId]
      {
        ...,
        'id': _id,
        'slug': slug.current,
        'description': floorLocation.floor->title,
        'floor': {
          'id': floorLocation.floor._ref,
          'title': floorLocation.floor->title,
        },
        'floorLocation': {
          'location': floorLocation.location { x, y }
        },
        chapters[] {
          ...,
          'id': _key,
          image { ..., asset-> },
          image360 {
            ...,
            poster { ..., asset-> },
            image { ..., asset-> }
          },
          video {
            ...,
            'src': src.asset->url,
            poster { ..., asset-> }
          },
        }
      } |
      order(documentOrder asc)
    `,
    { floorId })
}

Floor.fetchStoryData = function fetchData({ params: { storySlug }, parentData: { stories } }) {
  const story = stories.find(x => x.slug === storySlug)
  if (!story) return null

  return { story }
}

export function Floor({ title, showDetailOnClick, image, onClick, active, visible }) {
  const [ready, setReady] = React.useState(false)
  const handleLoad = React.useCallback(() => setReady(true), [])

  /** This type is only correct for the active floor
    @type {import('/routeMap').routeMap['storyline']['floorPlan']['floor']} */
  const routes = useRoutes()
  const navigate = useNavigate()
  const { data } = usePageRouteData()
  const { stories = [], story: selectedStory = null } = data || {}

  const { y, opacity } = useSpring({
    y: 0,
    opacity: (visible && ready) ? 1 : 0,
    from: {
      y: -25,
      opacity: 0
    }
  })

  const pinTransitions = useTransition(
    (active && visible && ready) ? stories : [],
    x => x.id,
    {
      from: { opacity: 0, y: -50 },
      enter: { opacity: 1, y: 0 },
      leave: { opacity: 0, y: -25 },
      trail: 100
    }
  )

  return (
    <animated.div
      className={styles.component}
      data-context-visible={visible && 'true' /* TODO: do we need this? */}
    >
      <animated.button
        className={styles.floorButton}
        style={{ opacity, transform: y.interpolate(y => `translate3d(0, ${y}%, 0)`) }}
        {...{ onClick }}
      >
        <Image {...{ image }} onLoad={handleLoad} />
      </animated.button>

      {pinTransitions.map(({ item: story, props, key }) => {
        const { id, title, slug, floorLocation: { location: { x, y } } } = story
        return (
          <animated.div
            key={key}
            className={styles.floorPin}
            style={{
              left: `${x}%`,
              top: `${y}%`,
              transform: props.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
              opacity: props.opacity
            }}
          >
            <Pin
              active={selectedStory && selectedStory.id === id}
              onClick={() => {
                const targetRoute = showDetailOnClick ? routes.story.detail : routes.story
                navigate(targetRoute({ storySlug: slug })) // it would be tough to get typing right
              }}
              {...{ title }}
            />
          </animated.div>
        )
      })}
    </animated.div>
  )
}

function Pin({ active, title, onClick }) {
  return (
    <PinBase
      iconActive={iconMarkerActive}
      iconInactive={iconMarkerInactive}
      {...{ active, title, onClick }}
    />
  )
}

function Image({ image, onLoad }) {
  const [svg, setSvg] = React.useState('')
  const builder = useImageBuilder()
  const url = builder.image(image).maxWidth(image.asset.metadata.dimensions.width).url()
  const asSvg = image.asset.extension === 'svg'

  React.useEffect(
    () => {
      if (asSvg) return
      fetch(url)
        .then(async response => setSvg(await response.text()))
        .catch(reportError)
    },
    [asSvg, url]
  )

  React.useEffect(() => { onLoad() }, [svg, onLoad])

  const props = asSvg
    ? { dangerouslySetInnerHTML: { __html: svg } }
    : { children: <img src={url} /> }

  return (
    <div
      className={styles.componentImage}
      {...props}
    />
  )
}
