import iconMarkerOuter from '/images/icons/marker-outer.raw.svg'
import styles from './Pin.css'

export function Pin({ active, onClick, title, iconActive, iconInactive }) {
  return (
    <button
      type='button'
      className={cx(styles.component, active && styles.isActive)}
      aria-label={title}
      {...{ onClick }}
    >
      <div className={cx(styles.layout, active && styles.isActive)}>
        <div
          className={cx(styles.outer, active && styles.isActive)}
          dangerouslySetInnerHTML={{ __html: iconMarkerOuter }}
        />
        <div
          className={cx(styles.icon, active && styles.isActive)}
          dangerouslySetInnerHTML={{ __html: active ? iconActive : iconInactive }}
        />
      </div>
    </button>
  )
}
