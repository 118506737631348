import { LinkText } from '/sub/buildingBlocks/Link'
import styles from './TextLink.css'

export function TextLink({ to, children }) {
  return (
    <LinkText {...{ to }}>
      <span className={styles.component}>{children}</span>
    </LinkText>
  )
}
