import { StorySlide } from '/sub/story/StorySlide'
import { useKeenSlider } from 'keen-slider/react'

import styles from './StoryCarousel.css'
import { useIsMountedRef } from '@kaliber/use-is-mounted-ref'

const TOUCH_HOLD_THRESHOLD = 500

export function StoryCarousel({
  stories: providedStories, selectedStory, onSelectedStoryChange, onClose, stopOnEndOfStory
}) {
  const isMountedRef = useIsMountedRef()

  // We want the user to see all stories in this story line, so we place the first selected
  // story at the start. Since this is a carousel, the selected story will change, we only
  // want to re-arrange the given stories when the set of stories changes
  const stories = React.useMemo(
    () => {
      const index = providedStories.findIndex(x => x.id === selectedStory.id)
      return [...providedStories.slice(index), ...providedStories.slice(0, index)]
    },
    [providedStories /* ignore selectedStory, only recapture when provided stories change */]
  )

  const { index: chapterIndex, storyIndexes, previous: previousChapter, next: nextChapter } = useChapterIndex(selectedStory)
  const [running, setRunning] = React.useState(true)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const touchStartTimestampRef = React.useRef(null)
  const isSlidingRef = React.useRef(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: stories.length > 1,
    detailsChanged(s) {
      isSlidingRef.current = Boolean(s.track.details.rel)
      setCurrentSlide(s.track.details.rel)
    },
    dragEnded() {
      isSlidingRef.current = false
    },
    slideChanged(s) {
      const slideIndex = s.track.details.rel
      if (!isMountedRef.current) return
      onSelectedStoryChange(stories[slideIndex])

      if (slideIndex < stories.length) setRunning(true)
    }
  })

  const isLastChapter = !selectedStory.chapters || chapterIndex === selectedStory.chapters.length - 1

  return (
    <div
      // @ts-ignore typing of keen slider is wrong
      ref={sliderRef}
      className={cx(styles.component, 'keen-slider')}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    >
      {stories.map(({ id, title, chapters, description  }) => (
        <div key={id} className={cx(styles.slide, 'keen-slider__slide')}>
          <StorySlide
            active={id === selectedStory.id}
            onRunningChange={setRunning}
            onNext={handleNextChapter}
            chapterIndex={storyIndexes[id] || 0}
            chapters={chapters ?? []}
            {...{ running, onClose, id, title, description }}
          />
        </div>
      ))}
    </div>
  )

  function handlePointerDown() {
    touchStartTimestampRef.current = performance.now()
    if (!isLastChapter || !stopOnEndOfStory) setRunning(false)
  }

  function handlePointerUp(e) {
    if (isClickOnInteractiveElement(e)) return

    if (!isLastChapter || !stopOnEndOfStory) setRunning(true)

    // Pointer up shouldn't do anything when it's part of a slider interaction
    if (isSlidingRef.current) return

    // Only handle as a tap, when the pointer was down at most TOUCH_HOLD_THRESHOLD ms
    const touchHoldDuration = performance.now() - touchStartTimestampRef.current
    if (touchHoldDuration < TOUCH_HOLD_THRESHOLD) {
      (e.clientX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth < 0.2
        ? handlePreviousChapter()
        : handleNextChapter()
    }

    touchStartTimestampRef.current = null
  }

  function handleNextChapter() {
    const slideIndex = instanceRef ? currentSlide : -1

    if (selectedStory.chapters && !isLastChapter) {
      nextChapter()
    } else if (stopOnEndOfStory) {
      // do nothing
    } else if (slideIndex < stories.length - 1) {
      setTimeout(() => { if (isMountedRef.current) instanceRef && instanceRef.current.next() })
    } else {
      onClose()
    }
  }

  function handlePreviousChapter() {
    const slideIndex = instanceRef ? currentSlide : -1

    if (chapterIndex > 0) {
      previousChapter()
    } else if (slideIndex > 0) {
      setTimeout(() => instanceRef.current.prev())
    }
  }
}

function useChapterIndex(selectedStory) {
  const [storyIndexes, setStoryIndexes] = React.useState({})
  const { id } = selectedStory
  if (!storyIndexes.hasOwnProperty(id)) updateIndex(id, _ => 0)

  return {
    index: storyIndexes[id],
    storyIndexes,
    next: React.useCallback(() => updateIndex(id, x => x + 1), [id]),
    previous: React.useCallback(() => updateIndex(id, x => x - 1), [id]),
  }

  function updateIndex(id, f) {
    setStoryIndexes(x => ({ ...x, [id]: f(x[id]) }))
  }
}

function isClickOnInteractiveElement({ target, currentTarget }) {

  return (
    target &&
    target !== currentTarget &&
    currentTarget.contains(target) && (
      ['button', 'a'].includes(target.tagName.toLowerCase()) ||
      isClickOnInteractiveElement({ target: target.parentNode, currentTarget })
    )
  )
}
