import { reportError } from '/machinery/reportError'
import { Image } from '/sub/Image'
import { HeadingLg } from '/sub/typography/Heading'
import { ThreeSixtyImage } from '/sub/ThreeSixtyImage'
import { ModalFullscreen } from '/sub/modal/Modal'
import { requestDeviceOrientationEventPermission as requestPermission } from '/machinery/requestDeviceOrientationEventPermission'
import { ButtonCircle } from '/sub/button/ButtonCircle'
import styles from './Chapter360.css'
import icon360 from '/images/icons/360.raw.svg'

export function Chapter360({ title, image360, layoutClassName, onReadyChange, onRunningChange }) {
  const [threeSixtyModalVisible, setThreeSixtyModalVisible] = React.useState(false)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.image}>
        <Image
          cover
          layoutClassName={styles.imageLayout}
          image={image360.poster}
          aspectRatio={5 / 9}
          onLoad={() => onReadyChange(true)}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.text}>
          <HeadingLg>{title}</HeadingLg>
        </div>
      </div>

      <ButtonCircle layoutClassName={styles.button} onClick={openThreeSixtyModal} icon={icon360}>
        Bekijk 360° foto
      </ButtonCircle>

      <ModalFullscreen visible={threeSixtyModalVisible} onClose={closeThreeSixtyModal}>
        <ThreeSixtyImage image={image360.image} />
      </ModalFullscreen>
    </div>
  )

  async function openThreeSixtyModal() {
    try {
      onRunningChange(false)
      await requestPermission()
    } catch (error) {
      reportError(error)
    } finally {
      setThreeSixtyModalVisible(true)
    }
  }

  function closeThreeSixtyModal() {
    setThreeSixtyModalVisible(false)
    onRunningChange(true)
  }
}
