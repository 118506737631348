let supportsPassive = false

if (
  typeof window !== 'undefined' &&
  typeof window.addEventListener === 'function' &&
  typeof Object.defineProperty === 'function'
) {
  const options = Object.defineProperty({}, 'passive', {
    get() { supportsPassive = true }
  })

  window.addEventListener('_', null, options)
}

export { supportsPassive }
