import { useSanityContext } from '/machinery/Sanity'
import { reportError } from '/machinery/reportError'
import { OK, NOT_FOUND, FOUND } from '/machinery/statusCodes'
import { asRouteChain, useLocation, useLocationMatch, useNavigate } from '@kaliber/routing'
import qs from 'query-string'
import { useQuery, useQueryClient } from 'react-query'
import { routeMap } from '/routeMap'
import { hydrate } from 'react-query/hydration'

// TODO: this file needs some cleaup when moving to the general sanity project

/** @type {React.Context<import('react-query').UseQueryResult<any, unknown>>} */
const pageRouteDataContext = React.createContext(null)

export function PageRouteDataContextProvider({ children, dehydratedState }) {
  const queryClient = useQueryClient()

  // We don't want to set dehydratedState on hot reload, we might have navigated to another route on
  // the client
  callOnce({ windowPropertyToPreventCall: 'preventInitialDataAsQueryData' }, () => {
    hydrate(queryClient, dehydratedState)
  })

  const result = usePageRouteDataQuery()

  return <pageRouteDataContext.Provider value={result} {...{ children }} />
}

function useCurrentRouteInfo() {
  const { search } = useLocation()
  const locationMatch = useLocationMatch()

  if (!locationMatch) {
    const error = new Error('No match from routeMap, the route map should cover all urls')
    reportError(error)
    throw error
  }
  const { params, route } = locationMatch
  const query = qs.parse(search)

  return { route, params, query }
}

function createQueryKey({ type, route, params, query }) {
  return ['route', type, route?.(params), query]
}

export function usePageRouteData() {
  const data = React.useContext(pageRouteDataContext)
  if (!data) throw new Error('Please use a PageRouteDataProvider')
  return data
}

export function usePageRoutePrefetch() {
  const { client } = useSanityContext()
  const queryClient = useQueryClient()

  return React.useCallback(
    ({ route, params, query }) => {
      fetchRouteData({ client, route, params, query, queryClient }).then(noop).catch(e => console.error(e)) // TODO: reportError
    },
    [queryClient, client]
  )

  function noop() {}
}

function usePageRouteDataQuery() {
  const { client } = useSanityContext()
  const { route, params, query } = useCurrentRouteInfo()
  const queryClient = useQueryClient()
  const routeChain = asRouteChain(route)
  const routeForQueryKey = routeChain.reverse().find(x => x.data?.fetch)
  const queryKey = createQueryKey({ type: 'combined', route: routeForQueryKey, params, query })

  const placeholderData = Object.assign({}, ...routeChain.map(route => {
    return queryClient.getQueryData(
      createQueryKey({ type: 'individual', route, params, query })
    )
  }))

  const result = useQuery({
    queryKey,
    queryFn: async () => fetchRouteData({ client, queryClient, route, params, query }),
    placeholderData,
    enabled: Boolean(routeForQueryKey),
  })

  useDataBasedRedirect({ data: result.data, route, params })

  return result
}

function useDataBasedRedirect({ data, route, params }) {
  const dataBasedRedirect = getDataBasedRedirect({ data, match: { route, params } })
  const navigate = useNavigate()
  React.useEffect(
    () => {
      if (!dataBasedRedirect) return
      navigate(dataBasedRedirect.to, { replace: true })
    },
    [navigate, dataBasedRedirect]
  )
}

export function getDataBasedRedirect({ data, match }) {
  // TODO: we need a feature in @kaliber/routing for this (matching a route) because routes are not referentially equivalent (partially applied)
  if (match.params.storylineSlug && match.route(match.params) !== routeMap.storyline(match.params)) return
  const [floor, ...rest] = data.floors || []

  const floorRoute = routeMap.storyline.floorPlan.floor

  return floor && !rest.length && {
    status: FOUND,
    to: floorRoute({ storylineSlug: data.storyline.slug, floorSlug: floor.slug })
  }
}

export async function fetchRouteData({ client, queryClient, route, params, query, body = undefined }) {
  const defaultResult = { status: OK }
  const routeChain = asRouteChain(route)

  const result = await routeChain.reduce(
    async (resultPromise, route) => {
      const { status: parentStatus, ...parentData } = await resultPromise

      // If a parent failed to return a result stop processing
      if (parentStatus !== OK) return resultPromise

      const { fetch } = route.data || {}

      const queryKey = createQueryKey({ type: 'individual', route, params, query })
      const data = fetch
        ? await queryClient.fetchQuery({
          queryKey,
          queryFn: () => fetch({ client, route, params, query, body, routeMap, parentData })
        })
        : {}

      const combinedData = {
        ...parentData,
        ...data,
        status: data ? OK : NOT_FOUND
      }

      return combinedData
    },
    Promise.resolve(defaultResult)
  )

  return result
}

function callOnce({ windowPropertyToPreventCall }, f) {
  const hasWindow = typeof window !== 'undefined'
  const allowCall = !hasWindow || !window[windowPropertyToPreventCall]
  // Only set the initial data on the first mount. Using something other than `window` would
  // cause problems with hot reloading
  if (allowCall) f()
  if (allowCall && hasWindow) window[windowPropertyToPreventCall] = true
}
