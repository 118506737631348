import { Marker as ReactMapGlMarker } from 'react-map-gl'
import iconMarkerActive from '/images/icons/marker-active.raw.svg'
import iconMarkerInactive from '/images/icons/marker-inactive.raw.svg'
import { Pin } from '/sub/Pin'

export function Marker({ latitude, longitude, active, title, onClick }) {
  return (
    <ReactMapGlMarker {...{ latitude, longitude }}>
      <Pin
        iconActive={iconMarkerActive}
        iconInactive={iconMarkerInactive}
        {...{ active, title, onClick }}
      />
    </ReactMapGlMarker>
  )
}
