import groq from 'groq'
import waterLineDesktop from '/images/icons/water-line-desktop.raw.svg'
import waterLineMobile from '/images/icons/water-line-mobile.raw.svg'
import { PageTransition } from '/sub/motion/PageTransition'
import { Header } from '/sub/layout/Header'
import { ButtonCallToActionTransparent } from '/sub/button/Button'
import { usePageRouteData } from '/machinery/PageRouteData'
import styles from './HomeSimple.css'
import { useRoutes } from '@kaliber/routing'
import { Icon } from '/sub/Icon'
import { useMediaQuery } from '/machinery/useMediaQuery'
import { breakpointMd } from '/cssGlobal/media.css'


HomeSimple.fetchData = async ({ client }) => {
  const [storylines, home] = await Promise.all([
    client.fetch(
      groq`
        *[_type == 'storyline' && (!defined(hideOnFrontPage) || !hideOnFrontPage)]
        { ..., 'id': _id, 'slug': slug.current, indexLinks[]-> } |
        order(documentOrder asc)
      `
    ),
    client.fetch(
      groq`
        *[_type == 'home']
        {
          ...,
          indexLinks[]->{..., 'id': _id, 'slug': slug.current}
        } |
        order(documentOrder asc) [0]
      `
    )
  ])

  return { storylines, home }
}

export function HomeSimple() {
  /** @type {import('/routeMap').routeMap} */
  const routes = useRoutes()
  const { data } = usePageRouteData()
  const { storylines, home } = data ?? {}
  const { indexTitle, indexLinks } = home ?? {}
  const minMdView = useMediaQuery({ query: `(min-width: ${breakpointMd})`, initialValue: false })

  return (
    <div className={styles.page}>
      <PageTransition>
        <div className={styles.layout}>
          <header className={styles.header}>
            <Header />
          </header>
          <img
            className={styles.fullscreenImage}
            alt='background'
            src='/images/frontpage.png'
          />
          <main className={styles.main}>
            <div className={styles.rondleidingen}>
              <div className={styles.content}>
                {indexTitle && <h2>{indexTitle}</h2>}

                <ul className={styles.list}>
                  {storylines && storylines.map(({ id, title, slug }) =>
                    <li key={id}>
                      <ButtonCallToActionTransparent to={routes.storyline({ storylineSlug: slug })}>
                        {title}
                      </ButtonCallToActionTransparent>
                    </li>
                  )}

                  {indexLinks && indexLinks.map(({ id, title, slug }) =>
                    <li key={id}>
                      <ButtonCallToActionTransparent to={routes.page({ pageSlug: slug })}>
                        {title}
                      </ButtonCallToActionTransparent>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className={styles.waterLine} >
              {minMdView
                ? <Icon icon={waterLineDesktop} />
                : <Icon icon={waterLineMobile} />
              }
            </div>
          </main>
        </div>
      </PageTransition>
    </div>
  )
}
