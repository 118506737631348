import { RouteTransition } from '/sub/motion/RouteTransition'

const transition = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0, position: 'absolute', top: 0, left: 0, right: 0, height: '100%' }
}

export function PageTransition({ children }) {
  return <RouteTransition {...{ transition, children }} />
}
