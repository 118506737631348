import { useSprings, animated, config } from 'react-spring'
import iconArrowDown from '/images/icons/arrow-down.raw.svg'
import iconArrowUp from '/images/icons/arrow-up.raw.svg'
import iconLayers from '/images/icons/layers.raw.svg'

import styles from './Floors.css'
import { useNavigate, useRoutes, useRouting } from '@kaliber/routing'
import { usePageRouteData } from '/machinery/PageRouteData'
import { Floor } from './Floor'

export function Floors({ floors }) {
  const { matchRoute } = useRouting()
  /** @type {import('/routeMap').routeMap['storyline']} */
  const routes = useRoutes()
  const navigate = useNavigate()
  const { data } = usePageRouteData()
  const { floor: selectedFloor = null, story: selectedStory = null } = data || {}

  const showControls = Boolean(selectedFloor) && floors.length > 1
  const selectedFloorIndex = floors.findIndex(x => x.id === selectedFloor?.id)

  const [springs, setSprings] = useSprings(floors.length, i => ({
    config: { ...config.slow, friction: 40 },
    transform: `translate3d(0, ${-i * 10}vh, 0)`,
    opacity: 0
  }))

  React.useEffect(
    () => { setSprings(i => to(i, floors, selectedFloor)) },
    [floors, selectedFloor, setSprings]
  )

  return (
    <div className={styles.component}>
      <div className={styles.map}>
        <div className={styles.floors}>
          {floors.map((floor, i) => {
            const { id, title, image, showTeaser } = floor
            const isActive = selectedFloor && selectedFloor.id === id
            const isVisible = !selectedFloor || isActive
            return (
              <animated.div
                key={id}
                className={styles.floor}
                style={{ ...springs[i] }}
              >
                {setFloorRouteContext(
                  <Floor
                    active={isActive}
                    visible={isVisible}
                    showDetailOnClick={!showTeaser}
                    onClick={() => onFloorSelect(floor)}
                    {...{ title, image }}
                  />
                )}
              </animated.div>
            )
          }
          )}
        </div>
      </div>

      {showControls && <div className={cx(styles.controls, selectedStory && styles.positionControls)}>
        <ControlButton
          disabled={selectedFloorIndex >= floors.length - 1}
          icon={iconArrowUp}
          onClick={() => onFloorSelect(floors[selectedFloorIndex + 1])}
        />
        <ControlButton
          icon={iconLayers}
          onClick={() => onFloorSelect(null)}
        />
        <ControlButton
          disabled={selectedFloorIndex <= 0}
          icon={iconArrowDown}
          onClick={() => onFloorSelect(floors[selectedFloorIndex - 1])}
        />
      </div>}
    </div>
  )

  function onFloorSelect(floor) {
    const to = floor
      ? routes.floorPlan.floor({ floorSlug: floor.slug })// it would be tricky to type this correctly
      : routes.floorPlan()

    navigate(to)
  }

  function setFloorRouteContext(children) {
    // This makes the active floor aware of the route, this simplifies navigation inside
    return matchRoute(routes.floorPlan.floor, children) || children
  }
}

function to(i, floors, selectedFloor) {
  const max = floors.length - 1
  const j = max - i
  const selectedFloorIndex = floors.findIndex(x => x.id === selectedFloor?.id)
  return selectedFloor
    ? { transform: `translate3d(0, ${max * 5 - 5 - 5 * (j - selectedFloorIndex)}vh, 0)`, opacity: selectedFloorIndex === i ? 1 : 0 }
    : { delay: i * 50, transform: `translate3d(0, 0vh, 0)`, opacity: 1 }
}

function ControlButton({ disabled = false, icon, onClick  }) {
  return (
    <button
      className={styles.componentControlButton}
      onClick={handleOnClick}
      dangerouslySetInnerHTML={{ __html: icon }}
      {...{ disabled }}
    />
  )

  function handleOnClick(e) {
    e.preventDefault()
    e.currentTarget.blur()
    onClick(e)
  }
}
