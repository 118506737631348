// returns a sign (-1, 0 or 1) based on a set of input criteria. Use this to
// determine wether to move back (-1), move forward (1) or stay put (0) after
// a drag event.

export function deriveDirectionSign({ velocityThreshold, offsetThreshold, velocity, offset }) {
  const velocitySign = Math.abs(velocity) >= velocityThreshold ? Math.sign(velocity) : 0
  const offsetSign = Math.abs(offset) >= offsetThreshold ? Math.sign(offset) : 0
  const sign = velocitySign || offsetSign
  return sign
}
