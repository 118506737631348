import { HeadingXxl } from '/sub/typography/Heading'
import styles from './ChapterQuote.css'

export function ChapterQuote({ text, layoutClassName, onReadyChange, color }) {
  React.useEffect(
    () => onReadyChange(true),
    [onReadyChange]
  )

  return (
    <div className={cx(
      styles.component,
      layoutClassName,
      color === 'white' ? styles.white :
      color === 'blue' ? styles.blue :
      color === 'green' ? styles.green :
      color === 'turquoise' ? styles.turquoise :
      styles.turquoise
    )}>
      <div className={styles.details}>
        <div className={styles.text}>
          <HeadingXxl>{text}</HeadingXxl>
        </div>
      </div>
    </div>
  )
}
