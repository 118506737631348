import { supportsPassive } from '/machinery/supportsPassive'

// This hook uses css to disable overscrolling/pull-to-refresh. This way, it's
// able to apply the effect without using non-passive event handlers which
// cause a notice jitter when dragging. Also, it only applies when scrolling
// inside the ref'ed element.

const supportsOverscrollBehavior = (
  typeof window !== 'undefined' &&
  typeof window.CSS !== 'undefined' &&
  CSS.supports('(overscroll-behavior: none')
)

export function usePreventOverscroll() {
  const nodeRef = React.useRef(null)
  const timeoutRef = React.useRef(null)
  const lockedRef = React.useRef(false)

  React.useEffect(
    () => {
      const options = supportsPassive ? { passive: true, capture: true } : true
      window.addEventListener('touchstart', handleTouchStart, options)
      window.addEventListener('touchend', handleTouchEnd, options)

      return () => {
        window.removeEventListener('touchstart', handleTouchStart, options)
        window.removeEventListener('touchend', handleTouchEnd, options)
        unlockScroll({ immediate: true })
      }

      function handleTouchStart(e) {
        if (nodeRef.current.contains(e.target)) lockScroll()
        else unlockScroll({ immediate: true })
      }

      function handleTouchEnd() {
        unlockScroll({ immediate: false })
      }
    },
    []
  )

  const ref = React.useCallback(
    node => {
      nodeRef.current = node
    },
    []
  )

  return { ref }

  function lockScroll() {
    if (lockedRef.current) return

    document.body.style.overscrollBehavior = 'none'

    if (!supportsOverscrollBehavior) {
      clearTimeout(timeoutRef.current)
      Object.assign(document.documentElement.style, {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      })
    }

    lockedRef.current = true
  }

  function unlockScroll({ immediate }) {
    if (!lockedRef.current) return

    document.body.style.overscrollBehavior = ''

    if (supportsOverscrollBehavior) {
      lockedRef.current = false
    } else {
      timeoutRef.current = setTimeout(
        () => {
          Object.assign(document.documentElement.style, {
            overflowY: '',
            position: '',
            top: '',
            left: '',
            width: '',
            height: ''
          })
          lockedRef.current = false
        },
        // TODO: Check if this delay is really needed or if it only fixes an glitch
        // in Browser Stack
        immediate ? 0 : 1000
      )
    }
  }
}
