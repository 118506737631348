import styles from './Header.css'
import blurpDesktop from '/images/icons/blurp-header-desktop.raw.svg'
import blurpMobile from '/images/icons/blurp-header-mobile.raw.svg'
import svgGeschiedenis from '/images/icons/geschiedenis.raw.svg'
import imageLogo from '/images/icons/logo.png'
import imageLogoColor from '/images/icons/logo-color.png'
import curl from '/images/icons/curl.raw.svg'
import { Icon } from '/sub/Icon'
import { HeadingLg } from '/sub/typography/Heading'
import { TextRegular } from '/sub/typography/Text'
import { useMediaQuery } from '/machinery/useMediaQuery'
import { breakpointMd } from '/cssGlobal/media.css'

export function Header() {
  const isMd = useMediaQuery({ query: `(min-width: ${breakpointMd})`, initialValue: null })
  const minMdView = !!isMd
  return (
    <div className={styles.component}>
      <div className={styles.background} >
        {minMdView &&
          <Icon icon={blurpDesktop} />
        }
      </div>
      <div className={styles.intro}>
        <div className={styles.introContent}>
          <HeadingLg h={1}>
            Hier stroomt <br />
            de{' '}
            <span className={styles.curlWrapper}>
              <span className={styles.curl}>
                <Icon icon={curl} />
              </span>
              <span className={styles.curlText}>
                IJssel
              </span>
            </span>
          </HeadingLg>
          <div className={styles.introLabel} dangerouslySetInnerHTML={{ __html: svgGeschiedenis }} />
        </div>
        <div className={styles.introDescription}>
          <TextRegular>
            Verhalen om de parels en de geheimen van de IJssellinie te ontdekken
          </TextRegular>
        </div>
      </div>
      <div className={styles.logo}>
        {minMdView
          ? <img src={imageLogo} alt='Logo' />
          : <img src={imageLogoColor} alt='Logo' />
        }
      </div>
      <div className={styles.topBackground} >
        {!minMdView &&
          <Icon icon={blurpMobile} />
        }
      </div>
    </div>
  )
}
