import { LinkBlock } from '/sub/buildingBlocks/Link'
import styles from './LinkButton.css'

export function LinkButton({ children, to, target = undefined, rel = undefined }) {
  return (
    <div className={styles.component}>
      <LinkBlock  {...{ to, target, rel }}>
        <div className={styles.children}>{ children }</div>
      </LinkBlock>
    </div>
  )
}
