import { ConfigProvider } from '/machinery/Config'
import { SanityProvider } from '/machinery/Sanity'
import { usePageRouteData, PageRouteDataContextProvider } from '/machinery/PageRouteData'

// Pages
import { HomeSimple } from '/pages/HomeSimple'
import { Page } from '/pages/Page'
import { Storyline } from '/pages/Storyline'
import { NotFound } from '/pages/NotFound'
import { ServerError } from '/pages/ServerError'
import { QueryClientProvider } from 'react-query'
import { routeMap } from './routeMap'
import { LocationProvider, useRoutes, useRouting } from '@kaliber/routing'
import { INTERNAL_SERVER_ERROR, NOT_FOUND } from './machinery/statusCodes'
import { createQueryClient } from './machinery/queryClient'
import { SettingsProvider } from './machinery/Settings'

export default function App({ settings, config, dehydratedState, location }) {
  /*
    We don't want the queries to be cached on the server, so we don't put them at the root of the
    file. This component is only rendered once, so the effect in the client is the same.
    This has the added benefit that hot reloading will clear the cache (are you sure?).
  */
  const queryClient = createQueryClient()

  return (
    <AppContextProvider {...{ config, settings, location, dehydratedState, queryClient }}>
      <AppImpl />
    </AppContextProvider>
  )
}

function AppContextProvider({
  config, settings, location, dehydratedState, queryClient, children
}) {
  return (
    <ConfigProvider value={config}>
      <SettingsProvider value={settings}>

        <QueryClientProvider client={queryClient}>
          <LocationProvider initialLocation={location} {...{ routeMap }}>
            <SanityProvider config={config.sanity}>
              <PageRouteDataContextProvider {...{ dehydratedState }}>
                {children}
              </PageRouteDataContextProvider>
            </SanityProvider>
          </LocationProvider>
        </QueryClientProvider>
      </SettingsProvider>
    </ConfigProvider>
  )
}

function AppImpl() {
  /** @type {import('/routeMap').routeMap} */
  const routes = useRoutes()
  const { matchRoutes } = useRouting()
  const { data } = usePageRouteData()
  return (
    data.status === INTERNAL_SERVER_ERROR ? <ServerError /> :
    data.status === NOT_FOUND ? <NotFound /> :
    matchRoutes(
      [routes.home, <HomeSimple />],
      [routes.page, <Page />],
      [routes.storyline, <Storyline />],
      [routes.notFound, <NotFound />],
    )
  )
}
