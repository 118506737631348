import { clamp } from '/machinery/clamp'

export function useRequestAnimationFrameProgressTracker({ duration, onTick, onComplete }) {
  const [isRunning, setIsRunning] = React.useState(false)
  const lastTimestampRef = React.useRef(0)
  const timePassedRef = React.useRef(0)
  const animationFrameRef = React.useRef(null)
  const pausedRef = React.useRef(false)
  const onTickRef = React.useRef(null)
  const onCompleteRef = React.useRef(null)
  onTickRef.current = onTick
  onCompleteRef.current = onComplete

  function start() {
    lastTimestampRef.current = performance.now()
    setIsRunning(_ => true)
    pausedRef.current = false
  }

  function pause() {
    pausedRef.current = true
  }

  function reset() {
    lastTimestampRef.current = performance.now()
    timePassedRef.current = 0
    onTickRef.current(0)
  }

  function stop() {
    cancelAnimationFrame(animationFrameRef.current)
    setIsRunning(_ => false)
    timePassedRef.current = 0
  }

  React.useEffect(
    () => {
      if (!isRunning) return

      animationFrameRef.current = requestAnimationFrame(handleFrame)

      return () => { cancelAnimationFrame(animationFrameRef.current) }

      function handleFrame(currentTimestamp) {
        if (!pausedRef.current) {
          const timeStep = currentTimestamp - lastTimestampRef.current
          timePassedRef.current = clamp(timePassedRef.current + timeStep, 0, duration)
          onTickRef.current(timePassedRef.current / duration)
        }

        if (timePassedRef.current === duration) {
          timePassedRef.current = 0
          setIsRunning(false)
          onCompleteRef.current(1)
        } else {
          lastTimestampRef.current = currentTimestamp
          animationFrameRef.current = requestAnimationFrame(handleFrame)
        }
      }
    },
    [isRunning, duration]
  )

  return {
    start: React.useCallback(start, []),
    pause: React.useCallback(pause, []),
    reset: React.useCallback(reset, []),
    stop: React.useCallback(stop, []),
  }
}
