import groq from 'groq'
import { useQuery } from 'react-query'
import styles from './ChapterLink.css'
import { useSanityContext } from '/machinery/Sanity'
import { LinkButton } from '/sub/button/LinkButton'
import { Icon } from '/sub/Icon'
import { HeadingLg } from '/sub/typography/Heading'
import iconArrowRightSmall from '/images/icons/arrow-right-small.raw.svg'

export function ChapterLink({ title, link, color, layoutClassName, onReadyChange }) {
  const { client } = useSanityContext()
  const { data } = useQuery({
    queryKey: ['document', link._ref],
    queryFn: () => client.fetch(
      groq`*[_id == $id][0] { _type, permalink, title }`,
      { id: link._ref }
    ),
    onSuccess: () => { onReadyChange(true) },
    placeholderData: {}
  })

  const url = data.permalink || ''
  return (
    <div className={cx(
      styles.component,
      layoutClassName,
      color === 'white' ? styles.white :
      color === 'blue' ? styles.blue :
      color === 'green' ? styles.green :
      color === 'turquoise' ? styles.turquoise :
      styles.turquoise
    )}>
      <div className={styles.details}>
        <HeadingLg>{title}</HeadingLg>
        {data && <LinkButton to={url}>
          <span>{data.title}</span>
          <Icon icon={iconArrowRightSmall} />
        </LinkButton>}
      </div>
    </div>
  )
}
