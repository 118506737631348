import { HeadingLg } from '/sub/typography/Heading'
import styles from './ChapterVideo.css'

export function ChapterVideo({ title, video, layoutClassName, running, onReadyChange, onDurationChange }) {
  const videoRef = React.useRef(null)

  React.useEffect(
    () => {
      if (running) videoRef.current.play()
      else videoRef.current.pause()
    },
    [running]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.video}>
        <video
          ref={videoRef}
          className={styles.videoLayout}
          src={video.src}
          onCanPlayThrough={handleCanPlayThrough}
          onWaiting={handleWaiting}
          onDurationChange={handleDurationChange}
        />
      </div>
      <div className={styles.text}>
        <HeadingLg>{title}</HeadingLg>
      </div>
    </div>
  )

  function handleCanPlayThrough() {
    onReadyChange(true)
  }

  function handleWaiting() {
    onReadyChange(false)
  }

  function handleDurationChange() {
    onDurationChange(videoRef.current.duration * 1000)
  }
}
