import styles from './Text.css'

export function TextRegular({ children }) {
  return <TextBase className={styles.componentRegular} {...{ children }} />
}

export function TextSmall({ children }) {
  return <TextBase className={styles.componentSmall} {...{ children }} />
}

function TextBase({ className, children }) {
  return (
    <div className={cx(styles.componentBase, className)}>{children}</div>
  )
}
