import cx from 'classnames'

import styles from './Heading.css'

const hRenderers = {
  h1: ({ children, ...rest }) => <h1 {...rest}>{children}</h1>,
  h2: ({ children, ...rest }) => <h2 {...rest}>{children}</h2>,
  h3: ({ children, ...rest }) => <h3 {...rest}>{children}</h3>,
  h4: ({ children, ...rest }) => <h4 {...rest}>{children}</h4>,
  h5: ({ children, ...rest }) => <h5 {...rest}>{children}</h5>,
  h6: ({ children, ...rest }) => <h6 {...rest}>{children}</h6>,
  strong: ({ children, ...rest }) => <strong {...rest}>{children}</strong>,
}

export function HeadingSm({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentSm} />
}

export function HeadingMd({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentMd} />
}

export function HeadingLg({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentLg} />
}

export function HeadingXl({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentXl} />
}

export function HeadingXxl({ children, h = undefined, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children, layoutClassName }} className={styles.componentXxl} />
}

function HeadingBase({ children, className, layoutClassName, h }) {
  const HBase = hRenderers[h ? `h${h}` : 'strong']

  // eslint-disable-next-line @kaliber/layout-class-name
  return <HBase className={cx(styles.componentBase, className, layoutClassName)}>{children}</HBase>
}
